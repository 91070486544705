.main-background {
  background-color: #04476E !important;
  color: white !important;
}

.secondary-blue-background {
  background-color: #2CA1E9 !important;
  color: white !important;
}

.reverse-main-background {
  background-color: white !important;
  color: #04476E !important;
}

.main-text {
  color: #04476E !important;
}

.white-text {
  color: white !important;
}

.secondary-text {
  color: #E6AF2E !important;
}

.grey-text {
  color: #E0E2DB !important;
}

.secondary-blue-text {
  color: #2CA1E9 !important;
}

.transparent-background {
  background-color: transparent !important;
}

.secondary-background {
  background-color: #E6AF2E !important;
  color: #04476E !important;
}

.green-background {
  background-color: #439775 !important;
  color: white !important;
}

.red-background {
  background-color: #F63528 !important;
  color: white !important;
}

.grey-background {
  background-color: #E0E2DB !important;
}

.purple-background {
  background-color: #a333c8 !important;
  color: white !important;
}

.discord-background {
  background-color: #7289da !important;
  color: white !important;
}

.no-margin {
  margin: 0 !important;
}

.no-side-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.only-left-margin {
  margin-left: 7px !important;
  margin-right: 0 !important;
}

.reduced-margin {
  margin: 0.5rem !important;
}

.no-border {
  border: none !important;
  border-radius: 0 !important;
  box-shadow: 0 0 0 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.reduced-padding {
  padding: 0.5rem !important;
}

.very-reduced-padding {
  padding: 0.25rem !important;
}

.text-align-right {
  text-align: right;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.no-side-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.very-side-padded {
  padding-right: 3em !important;
  padding-left: 3em !important;
}

.only-bottom-margin {
  margin-bottom: 14px !important
}

.no-bottom-padding {
  padding-bottom: 0 !important;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.live-icon {
  animation: fadeInOut 2s ease-in-out infinite; /* Adjust the duration as needed */
}